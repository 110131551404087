import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import { lighten, colors } from '../styles/colors';

import KitWrapper from './KitWrapper';
import KitSettingsWrapper from './KitSettingsWrapper';
import Kits from './Kits';

import Box from '../Box';
import { Store } from '../../state/store';
import AccessWrapper from '../AccessWrapper';
import MyUploads from './MyUploads';

function Assets() {
  const {
    state: { user },
  } = useContext(Store);

  return (
    <Box style={{ height: '100%' }} bg={lighten(colors.grey, 57)}>
      <Route path="/assets" exact>
        <AccessWrapper grant="content.assets">
          <Kits user={user} />
        </AccessWrapper>
      </Route>
      <Route path="/assets/uploads" exact>
        <MyUploads />
      </Route>
      <Route path="/assets/:kit(\d+|all)" exact>
        <KitWrapper />
      </Route>
      <Route path="/assets/:kit(\d+|all)/list" exact>
        <KitWrapper view="list" />
      </Route>
      <Route path="/assets/:kit(\d+)/settings" exact>
        <KitSettingsWrapper />
      </Route>
    </Box>
  );
}

Assets.propTypes = {};

export default Assets;
