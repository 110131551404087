import React, { useState, useEffect } from 'react';
import { P } from '../styles/typography';
import KitProps from '../../types/KitProps';
import { post } from '../../util/fetchUtil';
import FileProps from '../editor/types/FileProps';
import Button from '../Button';
import LoadingDots from '../animations/LoadingDots';

declare global {
  interface Window {
    ClipboardItem: any;
  }
}

type Props = {
  kit: KitProps;
};

const MAX_VISIBLE = 1000;

const HARDCODED_KITS_LIST = [
  { name: '1_Gumbies', id: 72 },
  { name: '2_Gumby Update', id: 73 },
  { name: '3_Approved Assets', id: 74 },
  { name: '4_Needs Update', id: 75 },
  { name: '5_No Tags', id: 76 },
  { name: '6_Quarantine', id: 77 },
  { name: '7_Ready for Review', id: 78 },
];

function SimpleAssetsList(props: Props) {
  const { kit } = props;
  const { kitID } = kit;

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FileProps[]>([]);
  const [visibleFiles, setVisibleFiles] = useState<FileProps[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileProps[]>([]);
  const [selectedKit, setSelectedKit] = useState(HARDCODED_KITS_LIST[0].id);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await post('assets/getKitFiles', { kitID });
      const set = new Set();
      setFiles(
        result.files.filter(
          (file: FileProps) => !set.has(file.fileID) && set.add(file.fileID)
        )
      );
      setVisibleFiles(result.files.slice(0, MAX_VISIBLE));
      setLoading(false);
    })();
  }, []);

  const handleMove = async () => {
    const fileIDs = selectedFiles.map((f) => f.fileID);
    await post('/assets/linkFilesToKit', {
      kitID: selectedKit,
      fileIDs,
    });

    // remove from current kit
    const { files: links } = await post('/assets/getFileKitLinks', {
      fileIDs,
      kitID: kitID || -1,
    });

    await post('/assets/reindex', {});

    const linkIDs = links
      .filter((l: any) => l.kitID === kitID)
      .map((l: any) => l.linkID);
    if (linkIDs.length) {
      await post('/assets/removeFileKitLinks', {
        linkIDs,
      });
    }
    setFiles(
      files.filter(
        (f) => !selectedFiles.map((file) => file.fileID).includes(f.fileID)
      )
    );
    setVisibleFiles(
      visibleFiles.filter(
        (f) => !selectedFiles.map((file) => file.fileID).includes(f.fileID)
      )
    );
    setSelectedFiles([]);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '10px',
        padding: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <P>
          {selectedFiles.length} file{selectedFiles.length === 1 ? '' : 's'}{' '}
          selected
        </P>
        <label htmlFor="move-to-kit">Move to:</label>
        <select
          name="move-to-kit"
          id="move-to-kit"
          value={selectedKit}
          onChange={(e) => setSelectedKit(Number(e.target.value))}
        >
          {HARDCODED_KITS_LIST.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <Button
          basic
          color={'blue'}
          disabled={!selectedFiles.length}
          onClick={handleMove}
        >
          🚀 Move
        </Button>
        <Button
          basic
          onClick={() => {
            setSelectedFiles([]);
          }}
        >
          Clear selection
        </Button>
      </div>
      {loading ? (
        <div>{<LoadingDots />}</div>
      ) : (
        visibleFiles.map((file: FileProps) => (
          <div
            key={file.fileID}
            id={`${file.fileID}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <input
              type="checkbox"
              checked={selectedFiles.includes(file)}
              onChange={() => {
                if (selectedFiles.includes(file)) {
                  setSelectedFiles(selectedFiles.filter((f) => f !== file));
                } else {
                  setSelectedFiles([...selectedFiles, file]);
                }
              }}
            />
            <a href={file.data.url} target="_blank" rel="noreferrer">
              <img
                src={file.data.url}
                alt={file.name}
                loading="lazy"
                width={120}
              />
            </a>
            <P>{file.name}</P>
            <P>{file.tags}</P>
          </div>
        ))
      )}
      {files.length > MAX_VISIBLE && (
        <div>
          <P>
            Showing {visibleFiles.length} of {files.length} files
          </P>
          <button onClick={() => setVisibleFiles(files)}>Show all</button>
        </div>
      )}
    </div>
  );
}

SimpleAssetsList.defaultProps = {
  selectedFiles: [],
  files: [],
  canEdit: true,
};

export default SimpleAssetsList;
