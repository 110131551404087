import React from 'react';
import styled from 'styled-components/macro';
import { P, H2 } from '../styles/typography';
import themeColors from '../../styles/themeColors';
import { colors, darken } from '../styles/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const MessageContainer = styled.div`
  padding: 50px;
  padding-bottom: 30px;
  padding-top: 30px;
  border: 2px solid ${themeColors['dark-2']};
  border-radius: 5px;
  background-color: ${themeColors['negative-1']};
  max-width: 70%;
`;

const MessageHeading = styled(H2)`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
`;

const MessageText = styled(P)`
  text-align: center;
  color: ${darken(colors.grey, 20)};
`;

export default function LockedContent() {
  const message = {
    header:
      'Osmosify assets have been migrated to Canto digital asset management',
    text: "Please contact your administrator to get access to the new platform. We're excited to see you there!",
  };
  return (
    <Wrapper>
      <MessageContainer>
        <MessageHeading>{message.header}</MessageHeading>
        <MessageText>{message.text}</MessageText>
      </MessageContainer>
    </Wrapper>
  );
}
