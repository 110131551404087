import { getLockStatus } from '../../app/lockContent';

export const fetchLockContentStatus = async (dispatch) => {
  const lockStatus = await getLockStatus();
  return dispatch({
    type: 'FETCH_LOCK_STATUS',
    payload: !!lockStatus?.locked,
  });
};

export default {
  fetchLockContentStatus,
};
