import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { post } from '../../util/fetchUtil';

import Kit from './Kit';
import KitProps from '../../types/KitProps';

/**
 * Parses the kit param and gets the appropriate kit information
 *
 */
function KitWrapper({ view }: { view?: 'standard' | 'list' }) {
  const [kit, setKit] = useState<Partial<KitProps>>();

  const history = useHistory();
  const kitIDParam = useParams<{ kit: string }>().kit;

  const getKit = (kitID: number) =>
    post('/assets/getKit', {
      kitID,
    });

  // On start, check param and if it's an number, check if the kit exists,
  // If it's "all", return all items
  // Otherwise route back to /assets
  useEffect(() => {
    if (!Number.isNaN(parseInt(kitIDParam, 10))) {
      getKit(Number(kitIDParam)).then((res) => {
        if (res.kit) {
          setKit(res.kit);
        } else {
          // Route back to assets page
          history.push('/assets');
        }
      });
    } else if (kitIDParam === 'all') {
      // Set special "All"
      setKit({ name: 'All' });
    } else {
      // Route back to assets page
      history.push('/assets');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ height: '100%' }}>
      {kit && <Kit kit={kit as KitProps} canEdit={true} view={view} />}
    </div>
  );
}

export default KitWrapper;
