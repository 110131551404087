export default (state, action) => {
  switch (action.type) {
    case 'FETCH_USER':
      return { ...state, user: action.payload };
    case 'FETCH_TEAM':
      return { ...state, team: action.payload };

    case 'SET_SELECTED_COMMENT_ID':
      return { ...state, selectedCommentID: action.payload };

    case 'LOAD_ASSETS':
      return { ...state, assetsLoaded: action.payload };
    case 'SET_IS_FULL_SCREEN':
      return { ...state, isFullScreen: action.payload };
    case 'SET_DEBUG_BORDER_IS_VISIBLE':
      return { ...state, debugBorderIsVisible: action.payload };

    case 'FETCH_NOTIFICATIONS':
      return { ...state, notifications: action.payload };

    case 'SET_ANNOTATION_IS_ACTIVE':
      return { ...state, annotationIsActive: action.payload };
    case 'SET_ANNOTATION_MODE':
      return { ...state, annotationMode: action.payload };
    case 'SET_SAVED_ANNOTATION_SVG':
      return { ...state, savedAnnotationSvg: action.payload };
    case 'SET_CURRENT_ANNOTATION_SVG':
      return { ...state, currentAnnotationSvg: action.payload };

    case 'FETCH_SAVED_WORDS':
      return { ...state, savedWords: action.payload };
    case 'SET_MISSPELLED_WORDS':
      return { ...state, misspelledWords: action.payload };

    case 'SET_ACTIVE_VIEW':
      return { ...state, activeView: action.payload };

    case 'SET_OBJECTS':
      return { ...state, objects: action.payload };
    case 'SET_DIMENSIONS':
      return { ...state, sceneDimensions: action.payload };
    case 'SET_ACTIVE_FILE':
      return { ...state, activeFile: action.payload };
    case 'SET_LOCAL_FILE':
      return { ...state, localFile: action.payload };
    case 'SET_SCENE_MODE':
      return { ...state, sceneMode: action.payload };

    case 'FETCH_PROJECT_GROUPS':
      return { ...state, projectGroups: action.payload };
    case 'FETCH_ALL_PROJECTS':
      return { ...state, projects: action.payload };
    case 'FETCH_RECENT_PROJECTS':
      return { ...state, projects: action.payload };
    case 'FETCH_SUBSCRIBED_PROJECTS':
      return { ...state, projects: action.payload };
    case 'FETCH_PROJECTS_BY_GROUP':
      return { ...state, projects: action.payload };
    case 'SEARCH_PROJECTS':
      return { ...state, projects: action.payload };
    case 'SET_TOTAL_PROJECTS':
      return { ...state, totalProjects: action.payload };
    case 'SET_ACTIVE_PROJECT':
      return { ...state, activeProject: action.payload };
    case 'SET_ACTIVE_PROJECT_BY_PROJECT_ID':
      return { ...state, activeProject: action.payload };
    case 'FETCH_PROJECT':
      return { ...state, projects: action.payload };
    case 'FETCH_ACTIVE_PROJECT':
      return { ...state, activeProject: action.payload };
    case 'SET_PROJECTS':
      return { ...state, projects: action.payload };
    case 'FETCH_LOCK_STATUS':
      return { ...state, contentIsLocked: action.payload };
    default:
      console.error(`Couldn't find reducer for ${action.type}`);
      return state;
  }
};
