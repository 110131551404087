import { post, get } from '../util/fetchUtil';

export const getLockStatus = async () => {
  const res = await get('/getLockContentStatus');
  return res.data;
};

export const toggleLockStatus = async () => {
  const res = await post('admin/toggleLockContent');
  return res.data;
};

export default {
  getLockStatus,
  toggleLockStatus,
};
