export default {
  user: null,
  team: [],

  assetsLoaded: false,

  selectedCommentID: null,

  isFullScreen: false,

  debugBorderIsVisible: false,

  notifications: [],

  annotationIsActive: false,
  annotationMode: '',
  savedAnnotationSvg: '',
  currentAnnotationSvg: '',

  savedWords: [],
  misspelledWords: [],

  activeView: 'home',

  objects: [],
  activeFile: {},
  localFile: {},
  sceneMode: 'svg',
  sceneDimensions: {
    // TODO: MOVE ALL SCENE DIMENSION REFS
    w: 1920,
    h: 1080,
  },

  projects: [],
  projectGroups: [],
  totalProjects: 0,
  activeProject: {
    files: [],
    members: [],
    data: {},
    comments: [],
    credits: {},
  },
  contentIsLocked: false,
};
