import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { Configure, InstantSearch } from 'react-instantsearch-dom';

import { post } from '../../util/fetchUtil';

import AssetControlsWrapper from './controls/AssetControlsWrapper';
import AssetNavigation from './navigation/AssetNavigation';
import AssetsList from './AssetsList';
import KitUploader from './KitUploader';

import useModifier from '../../app/hooks/useModifier';

import { uploadFile } from '../../app/fileManager';
import { getExtension, getBasename } from '../../utilities/files';
import uuid from '../../app/utilities/uuid';
import KitProps from '../../types/KitProps';
import FileProps from '../editor/types/FileProps';
import { getTotalFiles } from '../../app/assetDirs';
import useSearchClient from '../../app/hooks/useSearchClient';
import { SearchIndex } from '../../types/search';
import SimpleAssetsList from './SimpleAssetsList';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

export type FilterProps = {
  attr: 'tags' | 'directories' | 'projects' | 'kits' | 'id';
  value: string | number;
  name?: string;
  modifier?: 'AND' | 'OR' | 'NOT';
};

type Props = {
  kit: KitProps;
  canEdit: boolean;
  view?: 'standard' | 'list';
};

const getAttrString = (filters: FilterProps[]) =>
  filters.reduce(
    (str, current, i, arr) =>
      `${str}${current.attr}:"${current.value}"${
        i < arr.length - 1 ? ` ${arr[i + 1].modifier || 'AND'} ` : ''
      }`,
    ''
  );

function Kit(props: Props) {
  const { kit, canEdit, view = 'standard' } = props;

  const [selectedFiles, setSelectedFiles] = useState<FileProps[]>([]);
  const [selectedDir, setSelectedDir] = useState<any>(); // FIXME:

  const [filters, setFilters] = useState<FilterProps[]>([]);
  const [refresh, setRefresh] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const { searchClient, indexName } = useSearchClient(SearchIndex.FILES);

  const cmdKeyDown = useModifier('cmd').isDown;

  const handleClearFilters = () => {
    setFilters([]);
    setSelectedDir(null);
  };

  const handleUpdate = async () => {
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setFilters([...filters]);
    }
    // eslint-disable-next-line
  }, [refresh]);

  const uploadToKit = async (
    fileObjects: { name: string }[],
    options: { index?: boolean } = {}
  ) => {
    const { index = true } = options;

    const uploads: Promise<{
      url: string;
      file: { name: string; size: number };
    }>[] = [];

    fileObjects.forEach((f: { name: string }) => {
      const key = `assets/${uuid()}.${getExtension(f.name)}`;
      uploads.push(uploadFile(f, key));
    });

    const uploadRes = await Promise.all(uploads);

    let newFiles = [];

    if (uploadRes.length) {
      const res = await post('/assets/addFilesToKit', {
        files: uploadRes.map((upload) => ({
          url: upload.url,
          name: getBasename(upload.file.name),
          type: getExtension(upload.file.name).slice(1),
          size: upload.file.size,
        })),
        kitID: kit.kitID,
        index,
      });

      newFiles = res.files;
    }

    return newFiles;
  };

  const selectFiles = (newFiles: FileProps[] = [], e: React.MouseEvent) => {
    e.stopPropagation();

    let newSelectedFiles: FileProps[] = [...selectedFiles];
    const selectingMultiple = cmdKeyDown || false;

    newFiles.forEach((file) => {
      if (selectingMultiple) {
        const fileIndex = newSelectedFiles.findIndex(
          (f) => f.fileID === file.fileID
        );
        // Deselect same file
        if (fileIndex > -1) {
          newSelectedFiles.splice(fileIndex, 1);
        } else {
          // Add to selection if cmd or ctrl held down
          newSelectedFiles.push(file);
        }
      } else {
        // Add one file
        newSelectedFiles = [file];
      }
    });

    setSelectedFiles(newSelectedFiles);
  };

  // Deselect all files
  const handleDeselectFiles = () => {
    const { pathname } = location;
    setSelectedFiles([]);

    history.push(pathname);
  };

  /**
   * Parses route params
   * f - a fileID to filter by
   * d - a directoryID to filter by
   *
   * @returns
   */
  const getParams = () => {
    const { search } = location;

    const params = search.substr(1).split('&');

    const fileIDs = params
      .filter((p) => p[0] === 'f')
      .map((f) => parseInt(f.substr(2), 10))
      .filter((f) => !Number.isNaN(f));

    const dirIDs = params
      .filter((p) => p[0] === 'd')
      .map((d) => parseInt(d.substr(2), 10))
      .filter((d) => !Number.isNaN(d));

    return { dirIDs, fileIDs };
  };

  // Get route params on first page load
  useEffect(() => {
    const getRouteFilters = async () => {
      const { dirIDs, fileIDs } = getParams();
      const newFilters = Array.from(filters);
      if (fileIDs.length) {
        const res = await post('/assets/getFilesByIDs', {
          fileIDs,
          kitID: kit.kitID || -1,
        });

        const fileFilters = res.files.map((file: FileProps) => ({
          attr: 'id' as const,
          value: file.fileID,
          modifier: 'OR' as const,
          name: file.name,
        }));

        newFilters.push(...fileFilters);
      }

      if (dirIDs.length) {
        // NOTE: not the most efficient way to do this
        const res = await post('/assets/getPopulatedDirectory', {
          directoryID: dirIDs[0],
          kitID: kit.kitID,
        });

        if (res.directory) {
          newFilters.push({
            attr: 'directories',
            value: res.directory.directoryID,
            name: res.directory.name,
          });
        }

        setSelectedDir(res.directory);
      }

      setFilters(newFilters);
    };

    getRouteFilters();
    // eslint-disable-next-line
  }, []);

  // Add search params to URL on update
  useEffect(() => {
    const { pathname } = location;

    const folderFilters = filters.filter(
      (newActiveFilter) => newActiveFilter.attr === 'directories'
    );

    let search = '';
    if (folderFilters && folderFilters.length) {
      search += `?d=${folderFilters[0].value}`;
    }

    selectedFiles.forEach((f) => {
      if (!search) search += `?f=${f.fileID}`;
      else search += `&f=${f.fileID}`;
    });

    history.push(`${pathname}${search}`);
    // eslint-disable-next-line
  }, [selectedFiles, filters]);

  const handleSetFilters = (newFilters: FilterProps[]) => {
    setFilters(newFilters);
  };

  const handleUploadFiles = async (fileList: { [index: string]: File }) => {
    const filesArray = Object.values(fileList);

    const newFiles = await uploadToKit(filesArray);

    const folderFilters = filters.filter((f) => f.attr === 'directories');
    if (folderFilters.length) {
      await post('/assets/addDirectoryFiles', {
        fileIDs: newFiles.map((f: FileProps) => f.fileID),
        directoryID: filters[0].value, // Should only have one folder active
        kitID: kit.kitID,
      });
    }

    await handleUpdate();

    setFilters([
      ...filters,
      ...newFiles.map((f: FileProps) => ({
        attr: 'id' as const,
        value: f.fileID,
        modifier: 'OR',
        name: f.name,
      })),
    ]);
  };

  let combinedFilters: FilterProps[] = [...filters];
  if (kit.name !== 'All')
    combinedFilters.push({ attr: 'kits', value: kit.kitID });

  let filtersAttribute = getAttrString(combinedFilters);

  if (
    selectedDir &&
    combinedFilters.some((filter) => filter.attr === 'directories')
  ) {
    let dirString = '';
    // Filter out directories filters
    combinedFilters = combinedFilters.filter(
      (filter) => filter.attr !== 'directories'
    );

    // Handle case for "Not in folder"
    if (selectedDir.directoryID === -1) {
      dirString = getAttrString(
        selectedDir.files.map((file: FileProps) => ({
          attr: 'id',
          value: file.fileID,
          modifier: 'OR',
        }))
      );
    } else {
      // Get all files in directory and subdirectories
      const files = getTotalFiles([selectedDir], selectedDir.directoryID);
      if (files.length) {
        dirString = getAttrString(
          files.map((file) => ({
            attr: 'id',
            value: file.fileID,
            modifier: 'OR',
          }))
        );
      } else {
        // Could do anything here, used for empty directory
        // since no results should show for empty directories
        dirString += 'directories:-2';
      }
    }

    filtersAttribute = `${getAttrString(combinedFilters)}${
      dirString ? ` AND ${dirString}` : ''
    }`;
  }

  return (
    <>
      {view === 'list' ? (
        <Container>
          <SimpleAssetsList kit={kit} />
        </Container>
      ) : (
        <InstantSearch
          indexName={indexName}
          searchClient={searchClient}
          refresh={refresh}
        >
          <Configure filters={filtersAttribute} hitsPerPage={50} />
          <Container>
            <AssetNavigation
              setFilters={handleSetFilters}
              filters={filters}
              selectedFiles={selectedFiles}
              selectFiles={selectFiles}
              kit={kit}
              uploadToKit={uploadToKit}
              selectedDir={selectedDir}
              setSelectedDir={setSelectedDir}
              canEdit={canEdit}
            />
            <KitUploader
              style={{ flex: 1 }}
              disabled={!canEdit || kit?.name === 'All'}
              handleUploadFiles={handleUploadFiles}
            >
              <AssetsList
                kit={kit}
                selectedFiles={selectedFiles}
                filters={filters}
                canEdit={canEdit}
                selectFiles={selectFiles}
                setFilters={setFilters}
                handleDeselectFiles={handleDeselectFiles}
                uploadToKit={uploadToKit}
                handleUploadFiles={handleUploadFiles}
                handleClearFilters={handleClearFilters}
              />
            </KitUploader>
            <AssetControlsWrapper
              kit={kit}
              selectedFiles={selectedFiles}
              handleDeselectFiles={handleDeselectFiles}
              handleUpdate={handleUpdate}
              setFilters={setFilters}
              canEdit={canEdit}
            />
          </Container>
        </InstantSearch>
      )}
    </>
  );
}

const { bool, shape, string, number } = PropTypes;

Kit.propTypes = {
  kit: shape({
    name: string,
    kitID: number,
  }),
  canEdit: bool,
};

Kit.defaultProps = {
  kit: {},
  canEdit: true,
};

export default Kit;
