import React, { useContext, useEffect, useState } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import checkPuppeteer from '../app/checkPuppeteer';
import getGlobalVariables from '../app/getGlobalVariables';

import { Store } from '../state/store';
import { fetchUserData } from '../state/user/actions';
import { fetchLockContentStatus } from '../state/lockStatus/actions';

import { post } from '../util/fetchUtil';
import Admin from './admin/Admin';
import KitPublicWrapper from './assets/KitPublicWrapper';
import Credits from './credits/Credits';
import CreditsExporter from './credits/CreditsExporter';
import ExporterWrapper from './exporter/ExporterWrapper';
import Manual from './manual/Manual';
import Landing from './pages/Landing';

import Login from './pages/Login';
import Main from './pages/Main';
import Pronounce from './pages/Pronounce';
import PrintComments from './PrintComments';
import SceneReviewWrapper from './review/SceneReviewWrapper';
import TextCheckerWrapper from './textchecker/TextCheckerWrapper';

const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [puppeteerActive, setPuppeteerActive] = useState(false);

  const {
    state: { user },
    dispatch,
  } = useContext(Store);

  const init = async () => {
    // Check to see if puppeteer is active
    const key = await checkPuppeteer();
    if (!key) {
      // Check to see if user's logged in before attempting to get user
      const res = await post('/user/checkLoggedIn');
      await fetchLockContentStatus(dispatch);
      if (res.loggedIn) await fetchUserData(dispatch);
    }

    setPuppeteerActive(!!key);
    setIsLoading(false);
    getGlobalVariables(key);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          {user && (
            <>
              <Switch>
                <Route exact path="/">
                  <HashRouter>
                    <Main />
                  </HashRouter>
                </Route>
                <Route exact path="/pronounce">
                  <Pronounce />
                </Route>
                <Route exact path="/credits">
                  <Credits />
                </Route>
                <Route path="/admin/:routeId?">
                  <Admin />
                </Route>
                <Route exact path="/manual">
                  <Manual />
                </Route>
                <Route exact path="/printcomments">
                  <PrintComments />
                </Route>
                <Route exact path="/review/:type/:slug">
                  <SceneReviewWrapper />
                </Route>
                <Route exact path="/textchecker">
                  <TextCheckerWrapper />
                </Route>
                <Route exact path="/kit/:slug">
                  <KitPublicWrapper />
                </Route>
                <Route exact path="/login">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </>
          )}

          {/* Puppeteer routes */}
          {(puppeteerActive || user) && (
            <>
              <Route exact path="/creditsexporter">
                <CreditsExporter />
              </Route>
              <Route exact path="/exporter">
                <ExporterWrapper />
              </Route>
            </>
          )}

          {/* Logged out routes */}
          {!user && !puppeteerActive && (
            <Switch>
              <Route exact path="/kit/:slug">
                <KitPublicWrapper />
              </Route>

              <Route exact path="/login">
                {user && <Redirect to="/" />}
                <Login />
              </Route>

              <Route path="/">
                <Landing />
              </Route>
            </Switch>
          )}
        </>
      )}
    </>
  );
};

export default AppRouter;
