import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import wait from '../../app/utilities/wait';
import { fade } from '../styles/colors';

type Props = {
  circleFill?: string;
};

function LoadingDots(props: Props) {
  const { circleFill } = props;
  const dot1Controls = useAnimation();
  const dot2Controls = useAnimation();
  const dot3Controls = useAnimation();

  const dotSequence = async (dotControls: {
    start: (args: any) => Promise<any>;
  }) => {
    await dotControls.start({ opacity: 1, scale: 1 });
    await wait(500);
    await dotControls.start({ opacity: 0, scale: 0 });
  };

  const animate = async () => {
    dotSequence(dot1Controls);
    await wait(250);
    dotSequence(dot2Controls);
    await wait(250);
    dotSequence(dot3Controls);
  };

  useEffect(() => {
    setInterval(() => {
      animate();
    }, 2000);
  }, []);

  const initial = { opacity: 0, scale: 0 };
  const transition = { easing: 'ease-in-out' };
  // const fill = circleFill || fade('white', 50);
  const fill = circleFill || 'blue';

  return (
    <svg
      height="100%"
      viewBox="0 0 94 42"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
    >
      <motion.circle
        initial={initial}
        animate={dot1Controls}
        transition={transition}
        cx={22.355}
        cy={21.433}
        r={5.502}
        fill={fill}
      />
      <motion.circle
        initial={initial}
        animate={dot2Controls}
        transition={transition}
        cx={47.475}
        cy={21.433}
        r={5.502}
        fill={fill}
      />
      <motion.circle
        animate={dot3Controls}
        initial={initial}
        transition={transition}
        cx={72.595}
        cy={21.433}
        r={5.502}
        fill={fill}
      />
    </svg>
  );
}

export default LoadingDots;
